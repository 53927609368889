<template>
  <div class="originalItem">
    <PullRefresh
        :disabled="isRefreshDisable"
        v-if="!isLoading"
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isNoData="isNoData"
        :error="error"
        :is-higeht-max="true"
        :hasAdvPagination="true"
    >
      <div v-for="(item, index) in videoList" :key="index">
        <!-- 顶部轮播样式 -->
        <template v-if="item.showType === 1">
          <OriginalSwiper :field="'allMediaInfo'" :section="item"></OriginalSwiper>
        </template>
        <!-- 六宫格竖屏样式 -->
        <template v-if="item.showType === 2">
          <SixGridVertical :section="item" :videoListField="'allMediaInfo'" :coverField="'verticalCover'"></SixGridVertical>
        </template>
        <!-- 六宫格横屏样式 -->
        <template v-if="item.showType === 3">
          <!-- 六宫格（横） -->
          <SixGridHorizontal :section="item"></SixGridHorizontal>
        </template>
        <!-- 四宫格竖屏样式 -->
        <template v-if="item.showType === 4">
          <FourGridVertical :section="item" :videoListField="'allMediaInfo'" :coverField="'verticalCover'"></FourGridVertical>
        </template>
      </div>
    </PullRefresh>
  </div>
</template>
<script>
import PullRefresh from "@/components/PullRefresh/index.vue";
import FourGridHorizontal from "@/components/FourGridHorizontal/index.vue";
import SixGridVertical from "@/components/SixGridVertical/index.vue";
import SixteenSlideVertical from "@/components/SixteenSlideVertical/index.vue";
import EightSlideVertical from "@/components/EightSlideVertical/index.vue";
import TwelveSlideHorizontal from "@/components/TwelveSlideHorizontal/index.vue";
import FourGridVertical from "@/components/FourGridVertical/index.vue";
import OneBigFourSmall from "@/components/OneBigFourSmall/index.vue";
import SixGridHorizontal from "@/components/SixGridHorizontal/index.vue";
import OriginalSwiper from "@/components/OriginalSwiper/index.vue";
import {querySubModuleList} from "@/api/app";
import {AdType, getAdItem, jumpAdv} from "@/utils/getConfig";

export default {
  name: "VideoModuleItem",
  props: {
    subModuleId: {
      type: String,
    },
  },
  components:{
    PullRefresh,
    // FourGridHorizontal,
    FourGridVertical,
    // OneBigFourSmall,
    SixGridHorizontal,
    // SixteenSlideVertical,
    // TwelveSlideHorizontal,
    // EightSlideVertical,
    OriginalSwiper,
    SixGridVertical,
  },
  data(){
    return {
      pageNumber: 1,
      pageSize: 10,
      isRefreshDisable: false,
      isLoading: true,
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      videoList: [],
      bannerList:[],
      jingangArea: [],
    }
  },
  mounted(){
    this.querySubModuleList('refresh');
    this.bannerList = getAdItem(AdType.COMMUNITY_RECOMMEND);
    let appConfig = this.$store.getters.appConfig;
    this.jingangArea = appConfig.jingangArea.list || [];
  },
  methods:{
    goAdv(item) {
      jumpAdv(item);
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.querySubModuleList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.querySubModuleList('refresh');
    },
    async querySubModuleList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        subModuleId: this.subModuleId,
      };
      let res = await this.$Api(querySubModuleList, req);
      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;
      const list = res.data.allSection || [];
      if (type === "refresh") {
        this.videoList = list;
      } else {
        this.videoList = this.videoList.concat(list);
      }
      if (this.pageNumber === 1 && this.videoList.length === 0) {
        this.isNoData = true;
      }
      if (!res.data.hasNext) {
        this.finished = true;
      }
    },
  }
}
</script>
<style scoped lang="scss">
.originalItem{
  height: calc(100vh - 150px);

  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  .swiperBox {

    .swipe {
      width: 100vw;
      height: calc(100vw / 2.4);

      .van-swipe-item {
        width: 100%;
        color: #fff;
        font-size: 20px;
        line-height: 150px;
        text-align: center;
        background-color: rgb(43, 39, 72);
      }

      /deep/ .van-swipe__indicators {
        left: auto;
        right: 0;
        align-items: center;
      }

      /deep/ .van-swipe__indicator {
        width: 6px;
        height: 6px;
        background-color: rgba(148, 214, 218, 0.3);
      }

      /deep/ .van-swipe__indicator--active {
        width: 9px;
        height: 9px;
        background-color: #94d6da !important;
      }

      /deep/ img {
        object-fit: fill !important;
      }

      /deep/ .van-image__error{
        border-radius: 0;
      }
    }
  }

  .barTopic {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    // justify-content: space-between;
    //padding: 12px 0;
    padding: 0 10px;
    margin-top: 20px;
    font-size: 10px;
    color: #000000;
    text-align: center;
    //border-bottom: solid rgb(230, 230, 230) 1px;
    box-sizing: border-box;
    overscroll-behavior: contain;

    .barTopicItem {
      //width: 86px;
      flex: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 24px;
    }

    .barTopicItem:last-child {
      padding-right: 30px;
    }

    .topicImg {
      width: 50px;
      height: 50px;
      margin-bottom: 16px;
    }

    img {
      width: 42px;
      height: 42px;
    }

  }
}
</style>
<template>
  <div class="caricature">
    <!--    <div class="header flex-center-center">-->
    <!--      <img src="@/assets/png/originalTitlt.png" alt="" />-->
    <!--    </div>-->

    <!-- 原创模块会员判断 -->
    <van-overlay
      :show="!isVip && $route.path === '/original' && tabActive === 2"
      className="originalPop"
      z-index="99"
    >
      <div class="originalPopContent">
        <div class="tip1">温馨提示</div>
        <div class="tip2">该专题为<span>VIP免费</span>专享</div>
        <div class="tip3">您还不是会员 无法观看</div>
        <div class="tip4">开通会员 即可解锁免费观看</div>
        <div class="btn" @click="$router.push('/rechargePage?t=vip')">
          开通会员
        </div>
      </div>
    </van-overlay>

    <div class="mainBox">
      <van-tabs v-model="tabActive" class="tabs" @change="change">
        <van-tab v-for="(item, index) in modulesList" :key="item.id">
          <template #title>
            <div
              class="apprenticeActiveIcon"
              v-if="item.moduleName === '小师妹' && tabActive === index"
            ></div>
            <div
              class="apprenticeIcon"
              v-else-if="item.moduleName === '小师妹'"
            ></div>

            <div
              class="speakFreelyActiveIcon"
              v-if="item.moduleName === '有声漫' && tabActive === index"
            ></div>
            <div
              class="speakFreelyIcon"
              v-else-if="item.moduleName === '有声漫'"
            ></div>

            <div
              class="memberTopicActiveIcon"
              v-if="item.moduleName === '会员专题' && tabActive === index"
              src="@/assets/png/memberTopicActiveIcon.png"
              alt=""
            ></div>
            <div
              class="memberTopicIcon"
              v-else-if="item.moduleName === '会员专题'"
            ></div>
            <!-- <img class="apprenticeActiveIcon" v-if="item.moduleName === '小师妹' && tabActive === index" src="@/assets/png/apprenticeActiveIcon.png" alt="">
            <img class="apprenticeIcon" v-else-if="item.moduleName === '小师妹'" src="@/assets/png/apprenticeIcon.png" alt="">

            <img class="speakFreelyActiveIcon" v-if="item.moduleName === '有声漫' && tabActive === index" src="@/assets/png/speakFreelyActiveIcon.png" alt="">
            <img class="speakFreelyIcon" v-else-if="item.moduleName === '有声漫'" src="@/assets/png/speakFreelyIcon.png" alt="">

            <img class="memberTopicActiveIcon" v-if="item.moduleName === '会员专题' && tabActive === index" src="@/assets/png/memberTopicActiveIcon.png" alt="">
            <img class="memberTopicIcon" v-else-if="item.moduleName === '会员专题'" src="@/assets/png/memberTopicIcon.png" alt=""> -->
          </template>

          <!--          <originalItem :subModuleId="item.id"></originalItem>-->
          <VideoModuleItem
            :isAcg="true"
            :subModuleId="item.id"
            :moduleSort="1"
          ></VideoModuleItem>
        </van-tab>
      </van-tabs>

      <!--      <van-tabs>-->
      <!--        <van-tab>-->
      <!--          <template #title>-->
      <!--            <img src="@/assets/png/apprenticeIcon.png" alt="">-->
      <!--          </template>-->
      <!--        </van-tab>-->
      <!--        <van-tab>-->
      <!--          <template #title>-->
      <!--            <img src="@/assets/png/apprenticeIcon.png" alt="">-->
      <!--          </template>-->
      <!--        </van-tab>-->
      <!--        <van-tab>-->
      <!--          <template #title>-->
      <!--            <img src="@/assets/png/apprenticeIcon.png" alt="">-->
      <!--          </template>-->
      <!--        </van-tab>-->
      <!--      </van-tabs>-->

      <!--      <div class="head">-->
      <!--        <div class="swiperBox">-->
      <!--          <div class="carousel" v-if="bannerList.length">-->
      <!--            <div-->
      <!--                class="carousel-item"-->
      <!--                @touchstart="handleTouchstart"-->
      <!--                @touchmove="handleTouchmove"-->
      <!--                @touchend="handleTouchend"-->
      <!--                v-for="(item, index) in computedItems"-->
      <!--                @click="jump(bannerList[index], index)"-->
      <!--                :key="index"-->
      <!--                :style="{-->
      <!--                transform: `scale(${item.scale}) translate(${item.translateX}px, ${item.translateY}px)`,-->
      <!--                zIndex: item.zIndex,-->
      <!--                width: `${item.width}px`,-->
      <!--                height: `${item.height}px`,-->
      <!--              }"-->
      <!--            >-->
      <!--              <ImgDecypt-->
      <!--                  :src="bannerList[index].verticalCover"-->
      <!--                  :key="item.id"-->
      <!--                  :style="{ width: '100%', height: '100%' }"-->
      <!--                  :alt="'ImageError ' + (index + 1)"-->
      <!--              />-->
      <!--              <img v-if="index === currentIndex" class="originalPlay" src="@/assets/png/originalPlay.png" alt="">-->
      <!--            </div>-->
      <!--            <div class="carousel-indicators">-->
      <!--              <span-->
      <!--                  v-for="(item, index) in items"-->
      <!--                  :key="index"-->
      <!--                  :class="{ active: index === currentIndex }"-->
      <!--              ></span>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="bgIMage" :style="backgroundStyle"></div>-->
      <!--          &lt;!&ndash; <van-swipe class="swipe" :autoplay="3000" indicator-color="#FECD55">-->
      <!--            <van-swipe-item-->
      <!--              v-for="(item, index) in bannerList"-->
      <!--              :key="index"-->
      <!--              @click="goAdv(item)"-->
      <!--            >-->
      <!--              <ImgDecypt :src="item.cover" :key="item.cover" />-->
      <!--            </van-swipe-item>-->
      <!--          </van-swipe> &ndash;&gt;-->
      <!--        </div>-->
      <!--      </div>-->

      <!-- <van-sticky offset-top="9.59rem" @change="changeRefreshDisable"></van-sticky>
            <van-sticky offset-top="2.107rem" @change="suctionCap"></van-sticky> -->
      <!--      <van-sticky-->
      <!--        offset-top="7.466rem"-->
      <!--        @change="changeRefreshDisable"-->
      <!--      ></van-sticky>-->
      <!--      <van-sticky offset-top="2.107rem" @change="suctionCap"></van-sticky>-->
      <!--      &lt;!&ndash; 金刚区 &ndash;&gt;-->
      <!--      <div class="content">-->
      <!--        <div class="barTopic">-->
      <!--          <div-->
      <!--            class="barTopicItem"-->
      <!--            :class="-->
      <!--              index === 0-->
      <!--                ? 'jgItem1'-->
      <!--                : index === 1-->
      <!--                ? 'jgItem2'-->
      <!--                : index === 2-->
      <!--                ? 'jgItem3'-->
      <!--                : ''-->
      <!--            "-->
      <!--            v-for="(topicItem, index) in jingangArea"-->
      <!--            :key="topicItem.id"-->
      <!--            @click="moreMore(topicItem)"-->
      <!--          >-->
      <!--            &lt;!&ndash; <ImgDecypt-->
      <!--              class="topicImg"-->
      <!--              :src="topicItem.img"-->
      <!--              :key="topicItem.img"-->
      <!--            /> &ndash;&gt;-->
      <!--            <p>-->
      <!--              <span>{{ topicItem.name }}</span>-->
      <!--            </p>-->
      <!--          </div>-->
      <!--        </div>-->

      <!--        <div ref="swipeContent">-->
      <!--          <div class="videoContent">-->
      <!--            <PullRefresh-->
      <!--              :disabled="isRefreshDisable"-->
      <!--              v-if="!isLoading"-->
      <!--              :loading="loading"-->
      <!--              :refreshing="refreshing"-->
      <!--              :finished="finished"-->
      <!--              @onLoad="onLoad"-->
      <!--              @onRefresh="onRefresh"-->
      <!--              :isNoData="isNoData"-->
      <!--              :error="error"-->
      <!--              :is-higeht-max="true"-->
      <!--              :hasAdvPagination="true"-->
      <!--              className="Home"-->
      <!--            >-->
      <!--              <div v-for="(item, index) in videoList" :key="index">-->
      <!--                <VerticleThree :videoInfoData="item"></VerticleThree>-->
      <!--                &lt;!&ndash;                <VerticleTwo&ndash;&gt;-->
      <!--                &lt;!&ndash;                  v-if="item['direction'] == 2"&ndash;&gt;-->
      <!--                &lt;!&ndash;                  :videoInfoData="item"&ndash;&gt;-->
      <!--                &lt;!&ndash;                ></VerticleTwo>&ndash;&gt;-->
      <!--                &lt;!&ndash;                <TransverselyTwo&ndash;&gt;-->
      <!--                &lt;!&ndash;                  v-if="item['direction'] == 3"&ndash;&gt;-->
      <!--                &lt;!&ndash;                  :videoInfoData="item"&ndash;&gt;-->
      <!--                &lt;!&ndash;                ></TransverselyTwo>&ndash;&gt;-->
      <!--                &lt;!&ndash;                <TransverselyFive&ndash;&gt;-->
      <!--                &lt;!&ndash;                  v-if="item['direction'] == 4"&ndash;&gt;-->
      <!--                &lt;!&ndash;                  :videoInfoData="item"&ndash;&gt;-->
      <!--                &lt;!&ndash;                ></TransverselyFive>&ndash;&gt;-->
      <!--                &lt;!&ndash;                <TransverselyTwo&ndash;&gt;-->
      <!--                &lt;!&ndash;                  v-if="item['direction'] == 5"&ndash;&gt;-->
      <!--                &lt;!&ndash;                  :videoInfoData="item"&ndash;&gt;-->
      <!--                &lt;!&ndash;                  :num="6"&ndash;&gt;-->
      <!--                &lt;!&ndash;                >&ndash;&gt;-->
      <!--                &lt;!&ndash;                </TransverselyTwo>&ndash;&gt;-->
      <!--                &lt;!&ndash; <div-->
      <!--                  class="advertising"-->
      <!--                  v-if="advList.length > 0"-->
      <!--                  @click="goAdv(advList[index % advList.length])"-->
      <!--                >-->
      <!--                  <ImgDecypt-->
      <!--                    class="advImg"-->
      <!--                    :src="advList[index % advList.length].cover"-->
      <!--                  />-->
      <!--                </div> &ndash;&gt;-->
      <!--              </div>-->
      <!--            </PullRefresh>-->
      <!--            <Loading v-else />-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div style="width: 100%; height: 300px"></div>-->
    </div>

    <!--    &lt;!&ndash; 9 宫格广告 &ndash;&gt;-->
    <!--    <AdvSudoku-->
    <!--      :advList="advSudokuList"-->
    <!--      :show="advSudokuShow"-->
    <!--      @close="advSudokuClose"-->
    <!--    />-->

    <!--    &lt;!&ndash; 系统公告 &ndash;&gt;-->
    <!--    <AnnouncementText-->
    <!--      :advList="announcementTextList"-->
    <!--      :show="announcementTextShow"-->
    <!--      @close="announcementTextClose"-->
    <!--    />-->

    <!--    &lt;!&ndash; 图片广告 &ndash;&gt;-->
    <!--    <AdvPicture-->
    <!--      :advList="advPictureList"-->
    <!--      :show="advPictureShow"-->
    <!--      @close="advPictureClose"-->
    <!--    />-->

    <!-- <FloatingAdv /> -->
  </div>
</template>

<script>
import AdvSudoku from "@/components/AdvSudoku";
import AdvPicture from "@/components/AdvPicture";
import { queryTopicList } from "@/api/video";
import { originMedias, moduleList } from "@/api/original";
import Loading from "@/components/Loading";
import VerticleThree from "@/components/OriginalVideoList/VerticleThree";
import VerticleTwo from "@/components/OriginalVideoList/VerticleTwo";
import TransverselyTwo from "@/components/OriginalVideoList/TransverselyTwo";
import TransverselyFive from "@/components/OriginalVideoList/TransverselyFive";
import PullRefresh from "@/components/PullRefresh";
import ImgDecypt from "@/components/ImgDecypt";
import originalItem from "./originalItem/index.vue";
import FloatingAdv from "@/components/FloatingAdv";
import { imgDecyptApi } from "@/api/app";
import { mapGetters } from "vuex";
import store from "@/store";

import { getSessionItem, setSessionItem } from "@/utils/longStorage";

import {
  AdType,
  getAdItem,
  jumpAdv,
  getMarquee,
  MarqueeType,
  getOfficialConfigApps,
  getAnnouncementText,
} from "@/utils/getConfig";
import AnnouncementText from "@/components/AnnouncementText";
import VideoModuleItem from "@/components/videoModuleItem/index.vue";
export default {
  name: "Original",
  components: {
    VideoModuleItem,
    // originalItem,
    // PullRefresh,
    // Loading,
    // VerticleThree,
    // VerticleTwo,
    // TransverselyTwo,
    // TransverselyFive,
    // ImgDecypt,
    // AnnouncementText,
    // AdvSudoku,
    // AdvPicture,
    // FloatingAdv,
  },
  data() {
    return {
      backgroundImage: "",
      decyImg: [],
      startX: 0, // 触摸开始时的X坐标
      currentX: 0, // 当前轮播图的X坐标
      modulesList: [],
      tabActive: 0,
      items: [
        {
          width: 197,
          height: 262,
          scale: 1,
          translateX: 0,
          zIndex: 4,
        },
        {
          width: 84,
          height: 207,
          scale: 0.5,
          translateX: 50,
          zIndex: 3,
        },
        {
          width: 84,
          height: 130,
          scale: 0.4,
          translateX: 100,
          zIndex: 2,
        },
        {
          width: 74,
          height: 106,
          scale: 0.3,
          translateX: 150,
          zIndex: 1,
        },
      ],
      videoList: [],
      currentIndex: 0,
      swipeHeight: 0,
      isLoading: true,
      loading: true,
      isNoData: false,
      finished: false,
      suctionCapState: false,
      refreshing: false,
      marqueeText: null,
      error: false,
      bannerList: [],
      advSudokuShow: false, //九宫格广告弹窗
      advSudokuList: [], //九宫格广告数据
      announcementTextShow: false, //公告弹窗
      announcementTextList: [], //公告数据
      advPictureShow: false, //图片广告弹窗
      advPictureList: [], //图片广告数据
      listAdv: [], //首页列表广告
      advList: [], //专栏广告
      isRefreshDisable: false,
      jingangArea: [],
    };
  },
  activated() {
    this.tabActive = this.originalTabIndex;
    // if(this.$route.query.index){
    //   this.change(Number(this.$route.query.index));
    // }
  },
  computed: {
    ...mapGetters({
      isVip: "isVip",
      imgApi: "imgApi",
      originalTabIndex: "originalTabIndex",
    }),
    backgroundStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
      };
    },
    // 轮播图
    computedItems() {
      let tempIndex = this.currentIndex;
      let prevIndex = (tempIndex - 1 + this.items.length) % this.items.length;

      return this.items.map((item, index) => {
        let offset = index - tempIndex;
        if (offset < 0) offset += this.items.length;

        let firstTranslateY = index === tempIndex ? -15 : 0;
        let secondTranslateY =
          index === (tempIndex + 1) % this.items.length ? -5 : 0;
        let extraTranslateX = index === prevIndex ? 25 : 0;
        let endY = firstTranslateY + secondTranslateY;

        return {
          ...item,
          scale: 1 - 0.1 * offset,
          translateX: 110 * offset + 10 * (offset - 1) + extraTranslateX,
          translateY: endY,
          zIndex: this.items.length - offset,
          width: 197 - 31 * offset,
          height: 262 - 52 * offset,
        };
      });
    },
  },
  created() {
    this.queryTopicListInfo();
    // this.listAdv = getAdItem(AdType.COLUMN_ADV);
    // let appConfig = JSON.parse(getSessionItem("appConfig"));
    // this.jingangArea = appConfig.jingangArea.list || [];
  },
  mounted() {
    this.imgDecypt();
    // 轮播图
    this.getBannerList();
    // this.updateItems();
    setInterval(this.next, 4000);

    if (this.$route.query.index) {
      this.change(Number(this.$route.query.index));
    }

    this.modulesList = this.$store.getters.modulesInfo?.original || [];

    this.advSudokuList = getOfficialConfigApps();
    this.announcementTextList = getAnnouncementText();
    this.advPictureList = getAdItem(AdType.FREE_VIDEO).reverse();
    this.advList = getAdItem(AdType.COLUMN_ADV);
    this.marqueeText = getMarquee(MarqueeType.community);

    if (
      this.advSudokuList.length > 0 &&
      getSessionItem("advSudokuPop") != "1"
    ) {
      setSessionItem("advSudokuPop", "1");
      this.advSudokuShow = true;
    } else if (
      this.announcementTextList.length > 0 &&
      getSessionItem("announcementTextPop") != "1"
    ) {
      setSessionItem("announcementTextPop", "1");
      this.announcementTextShow = true;
    } else if (
      this.advPictureList.length > 0 &&
      getSessionItem("advPicturePop") != "1"
    ) {
      setSessionItem("advPicturePop", "1");
      this.advPictureShow = true;
    }
  },
  methods: {
    change(index) {
      this.$store.commit("app/SET_ORIGINALINDEX", index);
      this.tabActive = index;
      store.commit("app/SET_SCROLL_INFO", {
        key: this.$route.path,
        index: index,
      });
    },
    imgDecypt(src) {
      try {
        let imgApi = this.imgApi;
        if (imgApi.slice(-1) != "/") {
          imgApi = imgApi + "/";
        }
        if (!imgApi || !src) return;
        imgDecyptApi(imgApi + src).then((url) => {
          this.decyImg.push(url);
        });
      } catch (error) {
        console.log(error);
      }
    },
    moreMore(item) {
      this.$router.push({
        path: "topicPage",
        query: {
          topicId: item.id,
          zone: item.zone,
          name: item.name,
          format: 0,
        },
      });
    },
    // 轮播图视频跳转
    jump(item, index) {
      let kind = item["kind"];
      if (kind != null) {
        this.$router.push({
          path: "/videoDetailsPage",
          query: {
            id: item.id,
            kind: kind,
          },
        });
      }
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, "utf-8").toString("base64");
      return base64Str;
    },
    // 轮播图数据
    async getBannerList(type) {
      let res = await this.$Api(originMedias);
      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;
      try {
        if (res && res.code == 200) {
          // console.log(res);
          const { data } = res;

          data.list.forEach((item) => {
            let cover = item.verticalCover;
            this.imgDecypt(cover);
          });
          if (type == "refresh") {
            this.bannerList = data.list;
          } else {
            this.bannerList = this.bannerList.concat(data.list);
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    handleTouchstart(event) {
      this.startX = event.touches[0].clientX;
    },
    handleTouchmove(event) {
      const dx = event.touches[0].clientX - this.startX; // 计算滑动距离
      this.currentX += dx; // 更新轮播图位置
      this.startX = event.touches[0].clientX; // 更新触摸位置
    },
    handleTouchend() {
      if (this.currentX > 0) {
        this.next();
      } else if (this.currentX < 0) {
        this.previous();
      }
      this.currentX = 0; // 重置currentX
    },
    // 轮播图下一张
    next() {
      this.backgroundImage = this.decyImg[this.currentIndex];
      this.currentIndex = (this.currentIndex + 1) % this.items.length;
      // this.updateItems();
    },
    // 轮播图上一张
    previous() {
      this.backgroundImage = this.decyImg[this.currentIndex];
      this.currentIndex =
        (this.currentIndex - 1 + this.items.length) % this.items.length;
    },
    // updateItems() {
    //   let tempIndex = this.currentIndex;
    //   this.items.forEach((item, index) => {
    //     let offset = index - tempIndex;
    //     if (offset < 0) offset += this.items.length;
    //     item.scale = 1 - 0.1 * offset;
    //     // let extraTranslateX = (index === this.currentIndex) ? 100 : 0;
    //     let firstTranslateY = (index === tempIndex) ? -15 : 0;
    //     let secondTranslateY = (index === (tempIndex + 1) % this.items.length) ? -5 : 0;
    //     let extraTranslateX = (index === (tempIndex - 1 + this.items.length) % this.items.length) ? 25 : 0;
    //     let endY = firstTranslateY + secondTranslateY;
    //     item.translateY = endY;

    //     item.translateX = 110 * offset + 10 * (offset - 1) + extraTranslateX; // 调整每张图片的水平位移
    //     item.zIndex = this.items.length - offset;
    //     item.width = 197 - 31 * offset;
    //     item.height = 262 - 52 * offset;
    //   });
    // },
    advPictureClose() {
      /**
       * 图片广告
       */
      // this.advPictureList.pop();
      // if (this.advPictureList.length <= 0) {
      this.advPictureShow = false;
      // }
    },
    announcementTextClose() {
      /**
       * 公告弹窗关闭
       */
      this.announcementTextShow = false;

      if (
        this.advPictureList.length > 0 &&
        getSessionItem("advPicturePop") != "1"
      ) {
        setSessionItem("advPicturePop", "1");
        this.advPictureShow = true;
      }
    },
    advSudokuClose() {
      /**
       * 九宫格弹窗关闭
       */
      this.advSudokuShow = false;

      if (
        this.announcementTextList.length > 0 &&
        getSessionItem("announcementTextPop") != "1"
      ) {
        setSessionItem("announcementTextPop", "1");
        this.announcementTextShow = true;
      } else if (
        this.advPictureList.length > 0 &&
        getSessionItem("advPicturePop") != "1"
      ) {
        setSessionItem("advPicturePop", "1");
        this.advPictureShow = true;
      }
    },
    goAdv(item) {
      jumpAdv(item);
    },
    clickTag(index) {
      // this.suctionCapState = false;
      this.pageNumber = 1;
      this.finished = false;
      this.loading = true;
      this.isLoading = true;
      this.isNoData = false;
      this.queryTopicListInfo("refresh");
      this.swipeTo(index);
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.isNoData = false;
      this.queryTopicListInfo("refresh");
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.queryTopicListInfo();
    },
    async queryTopicListInfo(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        // topicMediaSize: 6,
        // zone: this.keyName,
      };
      try {
        let res = await this.$Api(moduleList, req);
        // console.log(res);
        const { data } = res;
        let listArr = res.data.normalSection || [];
        // console.log(data);
        // console.log(res.normalSection);
        this.loading = false;
        this.refreshing = false;
        this.isLoading = false;
        if (res && res.code == 200) {
          if (type == "refresh") {
            this.videoList = [];
            this.videoList = this.videoList.concat(listArr);
            this.jingangArea = data.section;
          } else {
            this.videoList = this.videoList.concat(listArr);
            this.jingangArea = this.jingangArea.concat(data.section);
          }

          if (this.pageNumber === 1 && this.videoList.length === 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext || res.data.list.length < req.pageSize) {
            this.finished = true;
          }
          // console.log(this.videoList, "-=-=");
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    suctionCap() {
      this.suctionCapState = !this.suctionCapState;
    },
    // 切换下拉禁用状态
    changeRefreshDisable(isFixed) {
      this.isRefreshDisable = !this.isRefreshDisable;
      // console.log(this.isRefreshDisable)
    },
    pageSlideSwipe(index) {
      /**
       * 页面滑动切换
       */
      let videoList = this.videoList;
      if (videoList.length == 0) {
        this.pageNumber = 1;
        this.refreshing = true;
        this.loading = true;
        this.isLoading = true;
        this.finished = false;
        this.isNoData = false;
        this.queryTopicListInfo("refresh");
      }
      this.swipeTo(index);
    },
    swipeTo(index) {
      this.$refs.slideSwipe.swipeTo(index);
      // this.$nextTick(()=>{
      //     this.swipeHeight = this.$refs.swipeContent[index].offsetHeight + 500;
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
// /deep/ .titleName {
//   color: #ffffff !important;
// }
// /deep/ .videoTxtTitle {
//   color: #ffffff !important;
// }
/deep/ .videoTxtTag {
  color: #999999 !important;
}

/deep/ .van-tabs__line {
  display: none;
}

/deep/ .van-tab {
  -webkit-flex: 0;
  padding: 0;
  margin-right: 20px;
}

/deep/ .van-tabs__nav {
  background: transparent;
}

/deep/ .van-tabs__wrap {
  padding-top: 19px;
  padding-bottom: 10px;
  padding-left: 16px;
  background: #A3B2D8;
}

.apprenticeIcon {
  width: 55.3px;
  height: 20px;
  background: url("../../assets/png/apprenticeIcon.png");
  background-size: 100% 100%;
}

.apprenticeActiveIcon {
  width: 75px;
  height: 35px;
  background: url("../../assets/png/apprenticeActiveIcon.png");
  background-size: 100% 100%;
}

.speakFreelyIcon {
  width: 55.3px;
  height: 20px;
  background: url("../../assets/png/speakFreelyIcon.png");
  background-size: 100% 100%;
}

.speakFreelyActiveIcon {
  width: 86px;
  height: 35px;
  background: url("../../assets/png/speakFreelyActiveIcon.png");
  background-size: 100% 100%;
}

.memberTopicIcon {
  width: 78.357px;
  height: 20px;
  background: url("../../assets/png/memberTopicIcon.png");
  background-size: 100% 100%;
}

.memberTopicActiveIcon {
  width: 102px;
  height: 35px;
  background: url("../../assets/png/memberTopicActiveIcon.png");
  background-size: 100% 100%;
}

/deep/ .van-overlay{
  top: 72px;
}

.originalPop {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 160px);

  .originalPopContent {
    font-size: 20px;
    color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      color: #56faf6;
    }

    .tip2,
    .tip4 {
      margin-top: 21px;
    }

    .tip4 {
      font-size: 14px;
    }

    .btn {
      width: 178px;
      height: 37px;
      border-radius: 30px;
      background: #fff;
      color: #b09fd9;
      font-size: 14px;
      font-weight: 900;
      margin-top: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.caricature {
  //height: 100%;
  // background: #191919;
  // overflow-y: auto;
  .header {
    width: 100%;
    height: 44px;
    line-height: 20px;
    // background: #000;
    //background: #ffffff;
    // font-size: 16px;
    // margin-bottom: 20px;
    img {
      width: 71px;
      height: 20px;
    }
  }
  .mainBox {
    //height: 100%;
    //overflow-y: auto;

    .tabs {
      //margin-top: 24px;
    }
  }

  .loading {
    height: 100%;
  }
}

.adImgBox {
  width: 100%;
  height: 88px;

  /deep/ img {
    object-fit: fill !important;
  }
}

.isFirst {
  margin-top: 85px !important;
}
</style>

<template>
  <div class="verticleThree">
    <div class="videoItem">
      <div class="titleBar">
        <div>
          <p class="titleName">{{videoInfoData.name}}</p>
          <p class="titleDetails">{{videoInfoData.desc}}</p>
        </div>
        <div class="moreRight" @click="moreMore" v-if="videoInfoData.name != '推荐'">
          <!-- <span class="moreRightTitle">更多</span> -->
          <img src="@/assets/png/moreRight.png" />
        </div>
      </div>
      <div class="videoInfo">
        <VerticleThreeList
          :videoList="videoInfoData['media']"
        ></VerticleThreeList>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 竖版视频列表（三）
 *  ps:
 *      direction = 0,  (一行)
 *      direction = 1   (两行)
 */
import VerticleThreeList from "@/components/OriginalVideoList/VerticleThreeList";
export default {
  name: "VerticleThree",
  components: {
    VerticleThreeList,
  },
  props: {
    videoInfoData: {
      type: Object,
    },
    tabNmae: String,
  },
  methods: {
    moreMore() {
      this.$router.push({
        path: "topicPage",
        query: {
          topicId: this.videoInfoData.id,
          zone: this.videoInfoData.zone,
          name: this.videoInfoData.name,
          format: 0,
          tabNmae:this.tabNmae,
          title: '妖精原创'
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.verticleThree {
  .videoItem {
    border-bottom: solid 1px #e6e6e6;
    padding-bottom: 12px;
    margin-top: 12px;

    .titleBar {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .titleName {
        font-size: 18px;
        color: #000000;
        font-weight: bold;
      }

      .titleDetails {
        font-size: 12px;
        color: #999999;
      }

      .moreRight {
        // margin-bottom: 14px;
        color: #000000;

        .moreRightTitle {
          font-size: 14px;
          margin-right: 6.1px;
        }

        img {
          width: 12px;
          height: 12px;
        }
      }
    }

    .videoInfo {
      margin-top: 9px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
</style>

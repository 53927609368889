<template>
  <div class="videoModuleItem">
    <PullRefresh
        :disabled="isRefreshDisable"
        v-if="!isLoading"
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isNoData="isNoData"
        :error="error"
        :is-higeht-max="true"
        :hasAdvPagination="true"
    >
      <!-- 轮播图 -->
<!--      <div class="swiperBox" v-if="bannerList && bannerList.length">-->
<!--        <van-swipe class="swipe" :autoplay="3000" indicator-color="#FECD55">-->
<!--          <van-swipe-item-->
<!--              v-for="(item, index) in bannerList"-->
<!--              :key="index"-->
<!--              @click="goAdv(item)"-->
<!--          >-->
<!--            <ImgDecypt :src="item.cover" :key="item.cover" />-->
<!--          </van-swipe-item>-->
<!--        </van-swipe>-->
<!--      </div>-->
     <!-- 轮播图5个 -->
     <div class="fiveGrid" v-if="bannerList && bannerList.length">
       <div class="rectAdvBox" @click="goAdv(item)" v-for="item in bannerList.slice(0, 10)" :key="item.id">
         <ImgDecypt class="rectAdvIcon" :src="item.cover" />
         <div class="rectName">{{ item.title }}</div>
       </div>
     </div>

     <div class="notice" v-if="isNotice && marqueeText">
       <img src="~@/assets/png/notice.png" />
       <van-notice-bar
         class="noticeBar"
         speed="20"
         color="rgb(0,0,0)"
         background="rgba(0,0,0,0)"
         :text="marqueeText"
       />
      </div>

      <!-- 金刚区 -->
      <div class="barTopic" v-if="jingangArea && jingangArea.length">
        <div
            class="barTopicItem"
            v-for="topicItem in jingangArea"
            :key="topicItem.id"
            @click="goAdv(topicItem)"
        >
          <ImgDecypt
              class="topicImg"
              :src="topicItem.img"
              :key="topicItem.img"
          />
          <p>
            <span>{{ topicItem.name }}</span>
          </p>
        </div>
      </div>

      <div v-for="(item, index) in videoList" :key="index">
        <!-- 顶部轮播样式 -->
        <template v-if="item.showType === 1">
          <!-- ACG 原创 顶部轮播样式 -->
          <OriginalSwiper :isAcg="isAcg" :isBtn="isBtn" :section="item"></OriginalSwiper>
        </template>
        <!-- 六宫格竖屏样式 -->
        <template v-else-if="item.showType === 2">
          <!-- 六宫格竖 -->
          <SixGridVertical :isAcg="isAcg" :isBtn="isBtn" :section="item"></SixGridVertical>
        </template>
        <!-- 六宫格横屏样式 -->
        <template v-else-if="item.showType === 3">
          <!-- 六宫格（横） -->
          <SixGridHorizontal :isAcg="isAcg" :isBtn="isBtn" :section="item"></SixGridHorizontal>
        </template>
        <!-- 四宫格竖屏样式 -->
        <template v-else-if="item.showType === 4">
          <!-- 四宫格（竖） -->
          <FourGridVertical :isAcg="isAcg" :isBtn="isBtn" :section="item"></FourGridVertical>
        </template>
        <!-- 四宫格横屏样式 -->
        <template v-else-if="item.showType === 5">
          <FourGridHorizontal :isAcg="isAcg" :isBtn="isBtn" :section="item" />
        </template>
        <!-- 四宫格竖屏列表样式 -->
        <template v-else-if="item.showType === 6">
          <!-- 四宫格加载（竖） -->
<!--          <FourGridVertical :isAcg="isAcg" :isBtn="isBtn" :section="item"></FourGridVertical>-->
          <FourGridLoadVertical :allData="justLookData" :isAcg="isAcg" :isBtn="isBtn" :section="item"></FourGridLoadVertical>
        </template>
        <!-- 企业影片样式 -->
        <template v-else-if="item.showType === 7">
          <!-- 企业影片 -->
          <DomesticMulitTopic :isAcg="isAcg" :isBtn="isBtn" :section="item"></DomesticMulitTopic>
        </template>
        <!-- 三横屏列表样式 -->
        <template v-else-if="item.showType === 8">
          <!-- 三横屏列表 -->
          <ThreeListHorizontal :isAcg="isAcg" :isBtn="isBtn" :section="item"></ThreeListHorizontal>
        </template>
        <!-- 八竖屏划动样式 -->
        <template v-else-if="item.showType === 9">
          <!-- 八屏划动（竖） -->
          <EightSlideVertical :isAcg="isAcg" :isBtn="isBtn" :section="item"></EightSlideVertical>
        </template>
        <!-- 十六竖屏划动样式 -->
        <template v-else-if="item.showType === 10">
          <!--  十六屏划动（竖）-- -->
          <SixteenSlideVertical :isAcg="isAcg" :isBtn="isBtn" :section="item"></SixteenSlideVertical>
        </template>
        <!-- 十二横屏划动样式 -->
        <template v-else-if="item.showType === 11">
          <!--  十二屏划动（横）-- -->
          <TwelveSlideHorizontal :isAcg="isAcg" :isBtn="isBtn" :section="item"></TwelveSlideHorizontal>
        </template>
        <!-- 片源推荐样式 -->
        <template v-else-if="item.showType === 12">
          <!-- 片源推荐小屏 -->
          <RecomFilmSources :isAcg="isAcg" :isBtn="isBtn" :section="item"></RecomFilmSources>
        </template>
        <!-- 情色电影样式 -->
        <template v-else-if="item.showType === 13">
          <!-- 情色电影 -->
          <EroticMovies :isAcg="isAcg" :isBtn="isBtn" :section="item"></EroticMovies>
        </template>
        <!-- 一大四小样式 -->
        <template v-else-if="item.showType === 14">
          <!-- 一大四小 -->
          <OneBigFourSmall :isAcg="isAcg" :isBtn="isBtn" :section="item"></OneBigFourSmall>
        </template>

        <!-- 短剧顶部轮播样式 -->
        <template v-else-if="item.showType === 15">
          <!-- 短剧轮播 -->
          <PlayletSwipe :isAcg="isAcg" :isBtn="isBtn" :section="item"></PlayletSwipe>
        </template>

        <!-- 短剧三竖屏滑动样式 -->
        <template v-else-if="item.showType === 16">
          <!-- 三竖屏滑动 -->
          <ThreeSlideVertical :moreBtnTxt="moreBtnTxt" :patternType="patternType" :isAcg="isAcg" :isBtn="isBtn" :section="item"></ThreeSlideVertical>
        </template>

        <!-- 短剧六宫格竖屏样式-->
        <template v-else-if="item.showType === 17">
          <!-- 六宫格竖 -->
          <SixGridVertical :moreBtnTxt="moreBtnTxt" :patternType="patternType" :isAcg="isAcg" :isBtn="isBtn" :section="item"></SixGridVertical>
        </template>

        <!-- ACG居左轮播样式 -->
        <template v-else-if="item.showType === 18">
         <OriginalSwiper :isAcg="isAcg" :section="item"></OriginalSwiper>
        </template>

        <!-- ACG居中轮播样式 -->
        <template v-else-if="item.showType === 19">
         <SwiperCenter :section="item"></SwiperCenter>
        </template>

        <!-- ACG六宫格竖屏样式 -->
        <template v-else-if="item.showType === 20">
         <SixGridVertical :isAcg="isAcg" :section="item"></SixGridVertical>
        </template>

        <!-- ACG四宫格竖屏样式 -->
        <template v-else-if="item.showType === 21">
         <FourGridVertical :isAcg="isAcg" :section="item"></FourGridVertical>
        </template>

        <!-- ACG九宫格竖屏样式 -->
        <template v-else-if="item.showType === 22">
         <NineGridVertical :isAcg="isAcg" :section="item"></NineGridVertical>
        </template>

       <!-- ACG二竖屏滑动样式 -->
       <template v-else-if="item.showType === 23">
        <EightSlideVertical :isAcg="isAcg" :section="item"></EightSlideVertical>
       </template>

       <!-- ACG二竖屏左右滑动样式 -->
       <template v-else-if="item.showType === 24">
         <ThreeSlideHorizontal :isAcg="isAcg" :section="item"></ThreeSlideHorizontal>
       </template>

       <!-- ACG一大四小样式 -->
       <template v-else-if="item.showType === 25">
        <!-- 一大四小 -->
        <OneBigFourSmall :isAcg="isAcg" :section="item"></OneBigFourSmall>
       </template>

       <!-- ACG四宫格竖屏滑动样式 -->
       <template v-else-if="item.showType === 26">
        <FourSmallHorizontal :isAcg="isAcg" :section="item"></FourSmallHorizontal>
       </template>

       <!-- ACG一排行榜 -->
       <template v-else-if="item.showType === 27">
        <Leaderboard :isAcg="isAcg" :section="item"></Leaderboard>
       </template>

       <!-- ACG随便看看样式 -->
       <template v-else-if="item.showType === 28">
        <JustLooking :allData="justLookData" :section="item" :list="videoList" />
       </template>

        <!-- 未找到的默认样式 -->
        <template v-else>
          <!-- 一大四小 -->
          <OneBigFourSmall :isAcg="isAcg" :section="item"></OneBigFourSmall>
        </template>

        <!-- 广告 -->
        <template v-if="advList.length > 0">
         <div
           class="advertising"
           v-if="advList.length > 0 && item"
           @click="goAdv(advList[index % advList.length])"
         >
          <ImgDecypt
            class="advImg"
            :src="advList[index % advList.length].cover"
          />
         </div>
        </template>
        <div class="halvingLine" v-if="halvingLineColor">
            <div class="line" :style="{background: halvingLineColor}"></div>
        </div>
      </div>
    </PullRefresh>
    <Loading v-else />
  </div>
</template>
<script>
import PullRefresh from "@/components/PullRefresh/index.vue";
import FourGridHorizontal from "@/components/FourGridHorizontal/index.vue";
import FourGridLoadVertical from "@/components/FourGridLoadVertical/index.vue";
import SixteenSlideVertical from "@/components/SixteenSlideVertical/index.vue";
import EightSlideVertical from "@/components/EightSlideVertical/index.vue";
import TwelveSlideHorizontal from "@/components/TwelveSlideHorizontal/index.vue";
import FourGridVertical from "@/components/FourGridVertical/index.vue";
import OneBigFourSmall from "@/components/OneBigFourSmall/index.vue";
import RecomFilmSources from "@/components/RecomFilmSources/index.vue";
import EroticMovies from "@/components/EroticMovies/index.vue";
import DomesticMulitTopic from "@/components/DomesticMulitTopic/index.vue";
import ThreeListHorizontal from "@/components/ThreeListHorizontal/index.vue";
import SixGridHorizontal from "@/components/SixGridHorizontal/index.vue";
import ThreeSlideHorizontal from "@/components/ThreeSlideHorizontal/index.vue";
import SixGridVertical from "@/components/SixGridVertical/index.vue";
import ThreeSlideVertical from "@/components/ThreeSlideVertical/index.vue";
import PlayletSwipe from "@/components/PlayletSwipe/index.vue";
import {querySubModuleList} from "@/api/app";
import {AdType, getAdItem, getMarquee, jumpAdv, MarqueeType} from "@/utils/getConfig";
import ImgDecypt from "@/components/ImgDecypt/index.vue";
import OriginalSwiper from "@/components/OriginalSwiper/index.vue";
import SwiperCenter from "@/components/SwiperCenter/index.vue";
import NineGridVertical from "@/components/NineGridVertical/index.vue";
import JustLooking from "@/components/VideoList/justLooking.vue";
import Leaderboard from "@/components/Leaderboard/index.vue";
import FourSmallHorizontal from "@/components/FourSmallHorizontal/index.vue";
import Loading from "@/components/Loading/index.vue";

export default {
  name: "VideoModuleItem",
  props: {
    subModuleId: {
      type: String,
    },
    moduleSort:{
      type: Number
    },
    bannerType:{    //轮播图的类型
      type: Number
    },
    jingangType:{   //金刚区类型
      type: Number
    },
    advType:{        //专栏广告类型
      type: Number
    },
    isAcg:{
      type: Boolean,
    },
    isBtn:{
      type: Boolean,
    },
    isNotice:{
      type: Boolean,
    },
    halvingLineColor:{
      type: String,
    },
    moreBtnTxt:{
      type: String,
    },
    patternType:{
      type: Number,
    }
  },
    components:{
      Loading,
      JustLooking,
      Leaderboard,
      OriginalSwiper,
      SwiperCenter,
      PullRefresh,
      FourGridHorizontal,
      FourGridLoadVertical,
      ImgDecypt,
      FourGridVertical,
      OneBigFourSmall,
      SixGridHorizontal,
      ThreeSlideHorizontal,
      SixteenSlideVertical,
      TwelveSlideHorizontal,
      EightSlideVertical,
      RecomFilmSources,
      SixGridVertical,
      EroticMovies,
      DomesticMulitTopic,
      ThreeListHorizontal,
      PlayletSwipe,
      ThreeSlideVertical,
     NineGridVertical,
     FourSmallHorizontal,
    },
    data(){
      return {
        pageNumber: 1,
        pageSize: 5,
        isRefreshDisable: false,
        isLoading: true,
        loading: true,
        refreshing: false,
        finished: false,
        isNoData: false,
        error: false,
        videoList: [],
        bannerList:[],
        jingangArea: [],
        justLookData: {
         hasNext: true,
         allSection: null,
        },
        advList: [], //专栏广告
        marqueeText: null,
      }
    },
  mounted(){
    this.querySubModuleList('refresh');
    // this.bannerList = getAdItem(AdType.COMMUNITY_RECOMMEND);
    if(this.bannerType){
      this.bannerList = getAdItem(this.bannerType);
    }
    // this.advList = getAdItem(AdType.COLUMN_ADV);
    if(this.advType){
      this.advList = getAdItem(this.advType);
    }

    if(this.jingangType){
      let appConfig = this.$store.getters.appConfig;
      let jingangArea = appConfig.jingangArea.list || [];
      this.jingangArea = jingangArea.filter((item)=>{
        if(item.type === this.jingangType){
          return true;
        }
      })
    }
   this.marqueeText = getMarquee(MarqueeType.community);
  },
    methods:{
      goAdv(item) {
        jumpAdv(item);
      },
      onLoad() {
        this.loading = true;
        if (this.error) {
          this.error = false;
        } else {
          this.pageNumber++;
        }
        this.querySubModuleList();
      },
      onRefresh() {
        this.pageNumber = 1;
        this.refreshing = true;
        this.finished = false;
        this.querySubModuleList('refresh');
      },
      async querySubModuleList(type) {
        let req = {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          subModuleId: this.subModuleId,
          moduleSort: this.moduleSort || 1,
        };
        let res = await this.$Api(querySubModuleList, req);
        this.loading = false;
        this.refreshing = false;
        this.isLoading = false;
        const list = res.data.allSection || [];
        if (type === "refresh") {
          this.videoList = list;
        } else {
          this.videoList = this.videoList.concat(list);
        }
        if (this.pageNumber === 1 && this.videoList.length === 0) {
          this.isNoData = true;
        }
        this.justLookData.hasNext = res.data.hasNext;
        if (!res.data.hasNext) {
          this.finished = true;
          this.justLookData.allSection = res.data.allSection;
        }
      },
    }
}
</script>
<style scoped lang="scss">
  .videoModuleItem{
    //height: calc(100vh - 40px);
    height: calc(100vh - 160px);
    //overflow-y: auto;
    //overflow-x: hidden;
    //-webkit-overflow-scrolling: touch;

    .swiperBox {

      .swipe {
        width: 100vw;
        height: calc(100vw / 2.4);

        .van-swipe-item {
          width: 100%;
          color: #fff;
          font-size: 20px;
          line-height: 150px;
          text-align: center;
          background-color: rgb(43, 39, 72);
        }

        /deep/ .van-swipe__indicators {
          left: auto;
          right: 0;
          align-items: center;
        }

        /deep/ .van-swipe__indicator {
          width: 6px;
          height: 6px;
          background-color: rgba(148, 214, 218, 0.3);
        }

        /deep/ .van-swipe__indicator--active {
          width: 9px;
          height: 9px;
          background-color: #94d6da !important;
        }

        /deep/ img {
          object-fit: fill !important;
        }

        /deep/ .van-image__error{
          border-radius: 0;
        }
      }
    }

    .fiveGrid {
     padding: 0 10px;
     margin-top: 18px;
     display: grid;
     grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
     grid-row-gap: 20px;
     grid-column-gap: 12px;
     .rectAdvBox {
      width: 60px;
      .rectAdvIcon {
       height: 60px;
       width: 60px;
       border-radius: 8px;
       overflow: hidden;
      }
      .rectName {
       font-size: 12px;
       color: #333;
       margin-top: 4px;
       overflow: hidden;
       white-space: nowrap;
       text-align: center;
      }
     }
    }

    .notice {
     margin: 20px 10px 0 10px;
     padding-left: 16px;
     background: #ffffff;
     color: #999999;
     height: 36px;
     //border-radius: 6px;
     display: flex;
     align-items: center;

     img {
      width: 16px;
      height: 16px;
     }

     /deep/ .van-notice-bar {
      padding: 0;
      font-size: 12px;
     }

     .noticeBar {
      width: 100%;
      height: 100%;
      color: #999999 !important;
      padding: 0 16px 0 10px;
     }
    }

    .advertising {
     padding: 0 12px;
     margin-top: 20px;
     border-radius: 8px;
     /deep/ .vanImage {
      border-radius: 8px;
     }
     /deep/ .van-image__img {
      border-radius: 8px;
     }
     .advertisingImg {
      width: 351px;
      height: 73.13px;
      background: rgb(43, 39, 72);
      border-radius: 6px;
     }
    }

    .halvingLine{
      margin: 18px 0;
      padding: 0 16px;

      .line{
        width: 100%;
        height: 1px;
      }
    }


    .barTopic {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      // justify-content: space-between;
      //padding: 12px 0;
      padding: 0 10px;
      margin-top: 20px;
      font-size: 10px;
      color: #000000;
      text-align: center;
      //border-bottom: solid rgb(230, 230, 230) 1px;
      box-sizing: border-box;
      overscroll-behavior: contain;

      .barTopicItem {
        //width: 86px;
        flex: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 24px;
      }

      .barTopicItem:last-child {
        padding-right: 30px;
      }

      .topicImg {
        width: 50px;
        height: 50px;
        margin-bottom: 16px;
      }

      img {
        width: 42px;
        height: 42px;
      }

    }
  }
</style>
